import {
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { presetComponentProps } from 'theme';
import { ButtonInPageProps, RenderInPageButton } from './ButtonInPage';
import qs from 'qs';

export interface VideoWithThumbnailProps {
  videoThumbnail: { url?: string; description?: string; width?: string; height?: string };
  headline: string;
  headlineColor: string;
  descriptionText: string;
}

const VideoWithThumbnail = ({
  videoThumbnail,
  headline,
  headlineColor,
  descriptionText,
  ...buttonProps
}: VideoWithThumbnailProps & ButtonInPageProps['entryData']): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isYouTubeVideo = buttonProps?.linkUrl?.includes('youtube.com');
  const videoId = isYouTubeVideo ? qs.parse(buttonProps?.linkUrl?.split('?')?.[1] || '')?.v : '';

  return (
    <>
      <Flex flexDir={{ base: 'column', sm: 'row' }} onClick={onOpen} cursor="pointer">
        <Box width={{ base: '100%', sm: '50%' }}>
          {videoThumbnail?.url && (
            <Image src={videoThumbnail?.url} alt={videoThumbnail?.description} height="auto" />
          )}
        </Box>
        <Box
          width={{ base: '100%', sm: '50%' }}
          maxWidth={{ base: '100%', sm: '50%' }}
          px={{ base: 0, sm: '2rem' }}
          py={{ base: '1.5rem', sm: '1rem' }}
        >
          {headline && (
            <Heading {...presetComponentProps?.h3} color={headlineColor}>
              {headline}
            </Heading>
          )}
          {descriptionText && (
            <Text mb="1rem" fontSize="sm">
              {descriptionText}
            </Text>
          )}
          <Box as="a" d="flex" justifyContent="flex-start" w="100%" m={0}>
            <RenderInPageButton {...{ entryData: buttonProps }} width="auto" />
          </Box>
        </Box>
      </Flex>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton top="1" right="2" />
          <ModalBody padding="8">
            <Box width="100%" height={{ base: '250px', md: '400px', lg: '500px' }}>
              <iframe
                src={
                  isYouTubeVideo
                    ? `https://youtube.com/embed/${videoId}?autoplay=1`
                    : buttonProps.linkUrl
                }
                allowFullScreen
                // ng-show="showvideo"
                width="100%"
                height="100%"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VideoWithThumbnail;
