import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import NextLink from 'next/link';

import { useStore } from 'store';
import { presetComponentProps } from 'theme';
import ProxiedImage from 'components/common/ProxiedImage';
import LDThemeButton from 'components/common/LDThemeButton';
import DirectionsIcon from '../../public/assets/icons/directions-icon.svg';
// import locationPlaceholder from '../../public/assets/location-placeholder.jpeg';
import { ImageCMSData } from 'types/cms';
import { GTMClickEvent } from 'utils/gtmHelpers';
import { useRouter } from 'next/router';

export interface LocationBannerProps {
  buttonColor?: string;
  buttonText?: string;
  buttonTextLink?: string;
  headlineColor?: string;
  showLocationImage?: boolean;
  storeLocation: {
    storeId: string;
  };
  inPartnershipWith?: ImageCMSData;
}

const LocationBanner = ({
  storeLocation,
  buttonColor,
  buttonText,
  buttonTextLink,
  headlineColor,
  showLocationImage,
  inPartnershipWith
}: LocationBannerProps): ReactElement => {
  const { asPath } = useRouter();
  const { locationStore } = useStore();

  const storeDetails = locationStore?.getSelectedStoreLocation(storeLocation.storeId);

  return (
    <Stack border="1px solid #AFAFAF" padding="8" spacing="3">
      {showLocationImage && storeDetails && (
        <Box>
          <ProxiedImage
            src={storeDetails?.image}
            width="100%"
            height="100%"
            objectFit="cover"
            layout="responsive"
            alt={`${storeDetails?.name} image`}
          />
        </Box>
      )}
      {storeDetails && (
        <Text
          {...presetComponentProps?.h3}
          color={headlineColor ? headlineColor : 'primary.default'}
        >
          {storeDetails?.name}
        </Text>
      )}
      {storeDetails && (
        <Stack spacing="1">
          <Text>{`${storeDetails?.address1} ${storeDetails?.address2}`}</Text>
          <Text>{`${storeDetails?.city}, ${storeDetails?.province}`}</Text>
        </Stack>
      )}
      {buttonText && buttonTextLink && (
        <Flex
          flex={1}
          alignItems={{ base: 'flex-start', md: 'flex-end' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <NextLink
            href={`https://www.google.com/maps/search/?api=1&query=london+drugs+${storeDetails?.name}+${storeDetails?.address1}+${storeDetails?.postalCode}`}
            passHref
          >
            <Box
              as="a"
              border="1px solid #E1E1E3"
              borderRadius="4px"
              paddingX="4"
              paddingY="2"
              marginRight={{ base: '0', md: '2' }}
              marginBottom={{ base: '2', md: '0' }}
              target="_blank"
              rel="noopener"
            >
              <DirectionsIcon width={30} height={30} />
            </Box>
          </NextLink>
          <NextLink href={buttonTextLink} passHref>
            <LDThemeButton
              as="a"
              bgColor={buttonColor || 'primary.default'}
              fontWeight="bold"
              width="100%"
              onClick={() => {
                GTMClickEvent(`${buttonText}`, asPath);
              }}
            >
              {buttonText}
            </LDThemeButton>
          </NextLink>
        </Flex>
      )}
      {inPartnershipWith && (
        <Box w="100%" d="flex" justifyContent="center">
          <Image
            src={inPartnershipWith?.url}
            width="100%"
            maxW="280px"
            mt="1"
            height="auto"
            objectFit="cover"
            // layout="responsive"
            alt={inPartnershipWith?.description}
          />
        </Box>
      )}
    </Stack>
  );
};

export default observer(LocationBanner);
