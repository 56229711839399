import ReactMarkdown from 'react-markdown';
import { Text, UnorderedList, ListItem } from '@chakra-ui/react';

import LDLink from './common/LDLink';
import { ReactElement } from 'react';
import { UnknownObjectAny } from 'global.types';

const MDXComponent = ({ children, ...props }: UnknownObjectAny): ReactElement => {
  const dontAddMargin = (index: number, siblingCount: number) => {
    return siblingCount - 1 === index ? 0 : undefined;
  };

  const components = {
    // a: ({ siblingCount, ...p }: UnknownObjectAny) => (
    //   <LDLink href={p.href} {...p} {...props} color="primary.default" />
    // ),
    // p: ({ siblingCount, ...p }: UnknownObjectAny) => {
    //   return <Text {...p} {...props} mb={dontAddMargin(p?.index, siblingCount) ?? '1rem'} />;
    // },
    // // Ordered prop removed due to errors
    // ul: ({ siblingCount, ...p }: UnknownObjectAny) => {
    //   return (
    //     <UnorderedList {...p} {...props} mb={dontAddMargin(p?.index, siblingCount) ?? '1rem'} />
    //   );
    // },
    // Ordered prop removed due to errors
    // li: ({ siblingCount, ...p }: UnknownObjectAny) => {
    //   return <ListItem {...p} {...props} />;
    // }
  };
  return (
    <ReactMarkdown includeElementIndex {...props}>
      {children}
    </ReactMarkdown>
  );
};

export default MDXComponent;
