
import cryptoJS from 'crypto-js';

export const handleToken = async (token: string, encryptToken?: boolean) => {
    const secret = process.env.KIBO_SECRET_ID ?? ""
    try {
        let encryptedToken, decryptedToken;
        if (!!encryptToken) {
            encryptedToken = cryptoJS.AES.encrypt(token, secret).toString()
        } else {
            const bytes = cryptoJS.AES.decrypt(token, secret)
            decryptedToken = bytes.toString(cryptoJS.enc.Utf8);
        }
        return { encryptedToken, decryptedToken }
    } catch (error) {
        console.log(`Error in handling token: ${error}`)
        return { encryptedToken: "", decryptedToken: "" }
    }
}