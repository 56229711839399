import { handleToken } from "utils/token";

export const searchStores = async (data: any, authToken: string) => {
  const { latitude, longitude, distance } = data;
  const site_id = process.env.KIBO_SITE_ID ?? "";
  const filters = latitude && longitude && distance ? `?filter=geo near(${latitude},${longitude},${distance * 1000})` : ""
  const url = `${process.env.KIBO_URL}/commerce/storefront/locationUsageTypes/SP/locations${filters ?? ""}`;
  try {
    const token = await handleToken(authToken);
    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token?.decryptedToken}`,
        "x-vol-site": site_id
      }
    });
    if (response?.status === 401) {
      const tokenResponse = await fetch(`${process.env.HOST_NAME}/api/auth_request`);
      const token = await tokenResponse?.json();
      const decryptedToken = await handleToken(token?.token)
      response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${decryptedToken?.decryptedToken}`,
          "x-vol-site": site_id
        }
      });
    }
    const stores = await response.json();
    if (!stores?.totalCount || stores?.totalCount === 0) {
      throw new Error('NO_STORES_FOUND');
    }
    return stores.items?.map((store: any) => ({
      ...store,
      pharmacyPhone: extractPharmacyPhone(store)
    }));
  } catch (error) {
    console.log("ERROR IN FETCHING STORES", error);
    return []
  }

};

export function extractPharmacyPhone(store: any) {
  try {
    const serviceshtml = store?.attributes?.find((attribute: any) => attribute?.fullyQualifiedName?.includes("serviceshtml"))?.values?.[0]?.split(',')
    const phone = serviceshtml?.[serviceshtml?.findIndex((i: string) => i === 'Pharmacy') + 1];
    if (!phone || phone === 'none') {
      return undefined;
    }
    return phone;
  } catch (error) {
    return undefined;
  }
}

// export const getStoreById = async (storeId: string) => {
//   const fetchUrl = `${url}/stores/${storeId}?client_id=${clientId}`;
//   const store = await fetch(fetchUrl).then((r) => r.json());
//   return store;
// };
